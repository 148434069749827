import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import {
	LoggerSymbol,
	ILogger,
	LoggerIntegrations,
	IRendererPropsExtender,
	RendererPropsExtenderSym,
} from '@wix/thunderbolt-symbols'
import { Environment } from '../../types/Environment'
import { create } from '@wix/fedops-logger'
import {
	commonBiLoggerFactory,
	createFedopsLogger,
	getBiStore,
	createConsoleLogger,
	getEnvironment,
	createLoggerApi,
} from '@wix/thunderbolt-commons'
// eslint-disable-next-line no-restricted-syntax
import { factory } from '@wix/web-bi-logger/dist/src/logger'

export function createLogger(loggerIntegrations: LoggerIntegrations): ILogger {
	const { sentry, wixBiSession, viewerModel, fetch } = loggerIntegrations
	const mode = viewerModel && viewerModel.mode ? viewerModel.mode : { qa: true }
	if (mode.qa || !sentry) {
		return createConsoleLogger()
	}

	const biStore = getBiStore(wixBiSession, viewerModel)
	const biLoggerFactory = commonBiLoggerFactory.createBiLoggerFactoryForFedops({
		biStore,
		fetch,
		muteBi: viewerModel.requestUrl.includes('suppressbi=true'),
		factory,
	})
	const fedopsLogger = createFedopsLogger({
		biLoggerFactory,
		phasesConfig: 'SEND_ON_START',
		appName: viewerModel.site && viewerModel.site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt',
		reportBlackbox: true,
		paramsOverrides: { is_rollout: biStore.is_rollout },
		factory: create,
	})
	const sentryStore = {
		release: process.env.browser ? window.thunderboltVersion : process.env.APP_VERSION,
		environment: getEnvironment(viewerModel.fleetConfig.code),
		user: wixBiSession.viewerSessionId,
	}
	const logger = createLoggerApi({
		fedopsLogger,
		sentry,
		sentryStore,
		shouldMuteErrors: biStore.isCached || wixBiSession.isjp,
		errorLimit: 50,
	})
	sentry.configureScope((scope: any) => {
		scope.addEventProcessor((event: any, hint?: any) => {
			const { message } = hint.originalException
			if (event.level === 'error') {
				fedopsLogger.interactionStarted('error', {
					paramsOverrides: {
						// @ts-ignore evid is a number
						evid: 26,
						errorInfo: message,
						errorType: event.type,
						eventString: hint.event_id,
						tags: event.tags,
					},
				}) // this is a workaround to get error rate until we will have support for postgresSQL in fedonomy
			}

			return event
		})
	})

	logger.setGlobalsForErrors({
		tags: { url: viewerModel.requestUrl, isSsr: !process.env.browser, ...viewerModel.deviceInfo },
		extra: { experiments: viewerModel.experiments },
	})
	return logger
}

const rendererPropsExtender = withDependencies(
	[LoggerSymbol],
	(logger: ILogger): IRendererPropsExtender => {
		return {
			async extendRendererProps() {
				return { logger }
			},
		}
	}
)

export const site = ({ logger }: Environment): ContainerModuleLoader => (bind) => {
	bind(LoggerSymbol).toConstantValue(logger)
	bind(RendererPropsExtenderSym).to(rendererPropsExtender)
}
